import { getInitialTheme } from '@cointracker/styleguide';
import { themeStore } from '../../../store';

interface ThemeProps {
  pathname: string;
}

export function RebrandTheme({ pathname }: ThemeProps) {
  themeStore.setState(() => ({
    theme: getInitialTheme({ pathname }),
  }));
}
